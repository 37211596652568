<template>
  <mfc-input-box :errors="cmpErrors" :clean="clean" :is-cleared="isCleared" :class="{fixed: fixed}">
     <div id="textarea-padding-top" :class="{visible: value, filled: value}" v-if="fixed"></div>
    <textarea ref="input"
           :class="{filled: cmpValue}"
           :name="name"
           v-model="cmpValue"
           v-bind="attrs"
           @input="update"
           @blur="onBlur"
           @focus="onFocus">
    </textarea>
    <label @click="focused">
      {{label}}
    </label>
    <div class="btn-additional">
      <slot name="additional"></slot>
    </div>
  </mfc-input-box>
</template>

<script>
  import inputMixin from '../../../mixins/inputMixin'
  import MfcInputBox from './MfcInputBox'
  import autosize from 'autosize'

  export default {
    name: "MfcTextarea",
    components: {
      MfcInputBox
    },
    props: {
      fixed: {
        type: Boolean,
        default: false
      }
    },
    mixins: [inputMixin],
    data() {
      return {}
    },
    computed: {
      isCleared() {
        return this.allowClean === false ? this.allowClean : !!this.cmpValue && !this.disabled
      }
    },
    methods: {
      update(e) {
        if (!this.fixed) autosize(this.getInput())
        this.$emit('input', this.cmpValue)
      },
      clean() {
        this.cmpValue = null
        this.cmpErrors = []
        this.update()
      },
      focused() {
        this.$refs.input.focus()
      },
      getInput() {
        return this.$refs.input
      },
      onFocus() {
        this.cmpErrors = []
        if (this.fixed) {
          let div = this.$el.querySelector("#textarea-padding-top");
          if (!div) {
            let textarea = this.$el.getElementsByTagName("textarea")[0];
            let div = document.createElement("div");
            div.id = "textarea-padding-top";
            div.setAttribute("class", "visible");
            for (let key in textarea.dataset) {
              div.setAttribute(`data-${key}`, "");
            }
            if (!this.cmpValue)
            this.$el.insertBefore(div, textarea);
          } else {
            div.setAttribute("style", "");
            div.setAttribute("class", "visible");
          }
        }
      },
      onBlur() {
        if (this.fixed) {
          let div = this.$el.querySelector("#textarea-padding-top");
          if (div) {
            if (!this.cmpValue) {
              div.remove();
            } else {
              div.setAttribute("class", "visible filled");
            }
          }
        }
      }
    },
    watch: {
      value(n) {
        if (n !== this.cmpValue)
          this.cmpValue = n
      }
    },
    updated: function () {
      setTimeout(() => {
        if (!this.fixed) autosize(this.getInput())
      }, 100)
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/mobile_styles/variables';
  @import '../../../assets/styles/colours';

  .input-box {
    &.with-error {
      textarea:not(:focus) {
        border-color: $error-color;
      }
      #textarea-padding-top {
        border-color: $error-color;
        border-bottom: unset;
      }
      &.fixed {
        textarea:not(:focus) {
          border-top: unset;
          &:not(.filled) {
            border-top: 1px solid $error-color;
          }
        }
      }
    }
  }

  label {
    font-size: $input-fs;
    position: absolute;
    left: 16px;
    top: 20px;
    color: rgb(135, 135, 135);
    transition: all $transition-dur;
    cursor: text;
  }

  #textarea-padding-top {
    height: 30px;
    background: white;
    border-radius: 8px;
    background-color: white;
    border: 1px solid rgb(218, 218, 218);
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    border-bottom: 1px solid white;
    display: none;

    &.visible {
      display: block;
    }

    &.filled {
      border: 1px solid rgb(245, 245, 245);
      background-color: rgb(245, 245, 245);
    }
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    background: rgb(245, 245, 245);
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    outline: none;
    cursor: text !important;
    box-shadow: none;
    resize: none!important;
    overflow: hidden;
    font-size: $input-fs;


    &:-webkit-autofill {
      // box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;
    }

    .input-box.bordered.fixed & {
        min-height: 196px;
        overflow-y: auto;

        &:not(.filled) {
          padding-top: 0px;
          border-top: 30px solid rgb(245, 245, 245);
        }
        &.filled {
          padding-top: 0px;
          border-top-left-radius: unset;
          border-top-right-radius: unset;
        }
        &:focus {
          border-top: 1px solid rgb(255, 255, 255);
          border-top-left-radius: unset;
          border-top-right-radius: unset;
          padding: 0px 16px 10px 16px;
        }
      }

    .input-box.bordered & {
      border: 1px solid rgb(245, 245, 245);
      border-radius: 8px;
      padding: 30px 16px 10px 16px;
      min-height: 166px;

      &.filled {
        min-height: 166px;
      }

      &::placeholder {
        color: rgba(117, 115, 111, 1);
      }

      &:focus {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(218, 218, 218);
        padding: 30px 16px 10px 16px;
        min-height: 166px;
      }

      &:focus ~ label, &.filled ~ label {
        font-size: 14px;
        top: 11px;
      }

      &.filled {

        &:disabled, &:read-only {
          background-color: rgb(245, 244, 242);
          border-color: transparent;
          color: rgba(117, 115, 111, 1);
        }

        &:disabled ~ label, &:read-only ~ label {
          color: rgb(158, 155, 152);
        }

        &:invalid {
          border-color: $error-color;
        }

        &:invalid ~ .btn-clean {
          background-color: $error-color;
        }
      }

      &:disabled, &:read-only {
        border-color: transparent;
      }

      &:disabled ~ label, &:read-only ~ label {
        color: rgb(223, 222, 218)
      }

      &:disabled::placeholder, &:read-only::placeholder {
        color: rgb(223, 222, 218);
      }
    }

  }

</style>
