<template>
  <a class="back-link" @click="goBack()">
    <i class="mfcicon-arrow"></i>
    <span>{{text}}</span>
  </a>
</template>

<script>
  export default {
    name: 'BackLink',
    props: ["text"],

    methods: {
      goBack() {
        if (!this.$attrs.href)
          window.history.back();
      }
    }
  }
</script>

<style lang='scss' scoped>
  .back-link {
    display: flex;
    text-decoration: none!important;
    color: #000;
    align-items: center;
    padding: 20px 0;
    width: fit-content;
    .mfcicon-arrow {
      transform-origin:center;
      transform: rotateY(180deg);
      opacity: 0.25;
      &:before {
        font-size: 14px;
        font-weight: 600!important;
      }
    }
    span {
      color: rgb(0, 0, 0);
      font-size: 16px;
      margin-left: 10px;
      opacity: 0.5;
    }
  }
</style>
