import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'

export default {
  props: ['departmentId', 'questionSubjectId', 'receptionId', 'scheduleId'],
  data: () => {
    return {
      errors: [],
      schedules: [],
      receptions: [],
      currentSchedule: '',
      uploading: false,
      reception: ''
    }
  },
  methods: {
    async getShedule(departmentId, questionSubjectId, cb) {
      try {
        this.uploading = true;
        this.reception = '';
        this.currentSchedule = '';

        const {data} = await this.$axios.get(this.$routes.schedules_path(), {
          params: {
            filter: {
              all: true,
              department_id: departmentId,
              question_subject_id: questionSubjectId
            }
          }
        });
        this.schedules = data.resources;
        if (cb) cb(this.schedules)
      } catch (e) {
        this.schedules = [];
        this.errors = ['Непредвиденная ошибка'];
        if (cb) cb(this.schedules)
      } finally {
        this.uploading = false
      }
    }
  },
  mounted() {
    this.getShedule(this.departmentId, this.questionSubjectId, () => {
      let schedule = find(this.schedules, (s) => {
        return s.id == this.scheduleId;
      });
      if (schedule && schedule.receptions) {
        this.currentSchedule = this.scheduleId;
        let r = find(schedule.receptions, (r) => {
          return r.id == this.receptionId;
        });
        if (r) {
          this.reception = r.id
        }
      } else {
        this.receptions = [];
        this.reception = ''
      }
    });
  },
  computed: {
    orderedReceptions: function () {
      return orderBy(this.receptions, 'start').filter(reception => Date.parse(reception.start) > new Date());
    }
  },
  watch: {
    questionSubjectId (newVal) {
      if (newVal) { this.getShedule(this.departmentId, newVal) }
    },
    currentSchedule (n, o) {
      let schedule = find(this.schedules, (s) => {
        return s.id == n
      });
      if (schedule && schedule.receptions) {
        this.receptions = schedule.receptions
        if (o != '') this.reception = ''
      } else {
        this.receptions = []
        this.reception = ''
      }
    }
  }
}