var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mfc-input-box',{attrs:{"errors":_vm.cmpErrors}},[_c('multiselect',{class:{filled: _vm.cmpValue},attrs:{"label":"label","track-by":"title","options":_vm.cmpOptions,"option-height":104,"multiple":_vm.multiple,"close-on-select":!_vm.multiple,"custom-label":_vm.customLabel,"show-labels":false,"preserve-search":true,"clear-on-select":false,"placeholder":'Поиск...',"disabled":_vm.disabled,"alphabet-order":_vm.alphabetOrder,"searchable":_vm.searchable},on:{"input":function($event){return _vm.update(_vm.cmpValue)},"open":function($event){_vm.cmpErrors = []}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('div',{staticClass:"singleLabel",class:{filled: _vm.cmpValue}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('div',{staticClass:"value",attrs:{"vb-ne":""}},[_vm._v(_vm._s(_vm.cmpValue.title))])])]}},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length)?_c('div',{staticClass:"singleLabel",class:{filled: _vm.cmpValue}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('div',{staticClass:"value",attrs:{"vb-ne":""}},_vm._l((values),function(val){return _c('span',[_vm._v(_vm._s(val.title))])}),0)]):_vm._e()]}},{key:"placeholder",fn:function(props){return [_c('div',{staticClass:"singleLabel"},[_c('label',[_vm._v(_vm._s(_vm.label))])])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [(_vm.multiple)?_c('div',{staticClass:"option"},[_c('span',{staticClass:"mark",class:{checked: _vm.isSelected(option)},attrs:{"row":"cn"}}),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v(_vm._s(option['title']))])]):_vm._e()]}},{key:"noResult",fn:function(){return [_vm._v("\n      Элементы не найдены\n    ")]},proxy:true},{key:"caret",fn:function(){return [_c('div',{staticClass:"multiselect__select"},[_c('i',{staticClass:"mfcicon-arrow-down"})])]},proxy:true},{key:"tag",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [_c('div')]}}]),model:{value:(_vm.cmpValue),callback:function ($$v) {_vm.cmpValue=$$v},expression:"cmpValue"}}),_vm._v(" "),(_vm.cmpValue)?_c('select',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"name":_vm.name,"multiple":_vm.multiple}},[_c('option'),_vm._v(" "),_vm._l((_vm.cmpOptions),function(option){return _c('option',{key:option['value'],domProps:{"value":option['value'],"selected":_vm.isSelected(option)}},[_vm._v("\n      "+_vm._s(option['title'])+"\n    ")])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }