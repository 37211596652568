<script>
  import MfcTextarea from '../form/MfcTextarea'

  export default {
    name: 'QualityClaim',
    components: {MfcTextarea},
    props: ["quality"],
    data() {
      return {
        new: true,
        stars: [1,2,3,4,5],
        qualityMark: undefined,
        commentSize: 0,
      }
    },
    methods: {
      setQualityMark (value) {
        if (!this.quality.id) {
          this.qualityMark = value
        }
      },
      changeComment (comment) {
        this.commentSize = comment ? comment.length : 0
      }
    },
    mounted() {
      if (this.quality) {
        this.changeComment(this.quality.comment);
        this.qualityMark = this.quality.mark;
      }
    }
  }
</script>

<style lang="scss">
  @import '../../../assets/mobile_styles/variables';

  form#new_quality {
    margin-bottom: 8px;
  }

  .quality-box {
    background: #e6d5cd;
    padding: 24px 16px;

    &.only-show {
      margin-bottom: 8px;

      .quality-mark {
        margin-bottom: unset;
        .star { cursor: default }
      }
      p.comment { margin-top: 20px}
    }

    .title {
      font-size: 24px;
      color: rgb(0, 0, 0);
      font-family: Circe;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .star {
      position: relative;
      color: whitesmoke;
      border-radius: 3px;
      cursor: pointer;
      color: #bbbbbb;
      margin-right: 8px;
      &.active {
        color: #ed5338;
      }
      i:before {
        font-size: 30px;
      }
    }

    .quality-mark {
    }

    &__body {
      .quality-mark {
        margin-bottom: 20px;
      }
      width: 100%;
      max-width: $max-width-mb;
      min-width: $min-width-mb;
      padding: 16px;
      border-radius: 6px;
      background: #ffffff;
      overflow-wrap: break-word;
      textarea {
        min-height: 128px !important;
        max-height: 128px !important;

        &:focus, &.filled {
          min-height: 98px !important;
        }
      }
      .actions {
        button {
          margin-right: 8px;
          margin-top: 16px;
         }
      }
    }


  }
</style>
