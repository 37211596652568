<template>
  <div class="section-box not-border" :class="{'disable': showOnly}">
    <div
       ref="dropArea"
       col="fs fs"
       wrap="">
      <div class="section-box-title" v-if="!showOnly"> Вложения </div>
      <div class="attributes-label" v-if="!showOnly"> В случае необходимости в подтверждении своих доводов вы вправе приложить к обращению необходимые документы и материалы в электронной форме 
        <i class="mfcicon-question cssSelector"></i>
      </div>
      <tippy toSelector=".cssSelector">
        Объем загружаемых файлов не более 50 Мб.
        Допустимые форматы:
        Видео: mp4, avi, mov. 
        Архивы: zip, rar, tar, 7z. 
        Изображения: tiff, jpg, jpeg, png. 
        Документы: doc, docx, pdf, txt, xlsx, xls, ods. 
        Иные форматы файлов не обрабатываются приложением.
      </tippy>
      <div class="documents">
        <div class="document" v-for="doc in documents" :key="doc.id">
          <a class="link" :href="doc.url" target="_blank">{{doc.description}}</a>
          <div class="action" @click="deleteDocument(doc)">Удалить</div>
        </div>
      </div>
      <button type="button" class="simple m-long" @click="imgSrc && showOnly ? false : $refs.file.click()" v-if="!showOnly"> Прикрепить</button>
    </div>

    <input type="file"
           ref="file"
           accept=".mp4, .avi, .mov, .mkv, .zip, .rar, .tar, .gz, .7z, .tiff, .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .xls, .xlsx"
           v-show="false"
           @change="handleFile($event.target.files)"
           :disabled="uploading || $attrs.disabled"
           v-bind="fileInputAttrs">
    <input type="hidden" v-for="doc in documents" :value="doc.id" :key="doc.id" :name="'claim[document_ids][]'">
    <input type="hidden" v-if="documents.length === 0" :name="'claim[document_ids][]'">
  </div>

</template>

<script>
  import first from 'lodash/first'
  import findIndex from 'lodash/findIndex'
  import isEqual from 'lodash/isEqual'


  export default {
    name: 'ClaimDocuments',
    props: {
      value: {
        type: [Array],
      },
      showOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        uploadedSrc: null,
        highlight: false,
        fileId: null,
        documents: []
      }
    },
    mounted () {
      this.documents = this.value;
      this.$store.commit('SET_STATE', {path: ['claim', 'attachments'], value: this.documents})
      this.$store.commit('SET_STATE', {path: ['initialAttachments'], value: this.value})
    },
    computed: {
      imgSrc() {
        return this.uploadedSrc || this.src
      },
      fileInputAttrs() {
        const {...attrs} = this.attrs
        return attrs
      },
      uploading: {
        get() {
          return this.$store.state.attachmentUploading
        },
        set(v) {
          this.$store.commit('SET_STATE', {path: ['attachmentUploading'], value: v})
        }
      }
    },
    methods: {
      async handleFile(files) {

        const file = first(files)
        if (!file || this.uploading) return

        try {
          this.uploading = true

          const formData = new FormData()

          formData.append('attachment[file]', file)
          formData.append('attachment[kind]', "claim_document")
          formData.append('attachment[type]', "Attachment::Document")

          const {data} = await this.$axios.post(this.$routes.attachments_path({format: "json"}), formData)
          this.documents.push(data.resource)
        } catch (e) {
          console.error("ERROR", e)
        } finally {
          this.$store.commit('SET_STATE', {path: ['attachmentChanged'], value: !isEqual(this.documents, this.$store.state.initialAttachments)})
          this.uploading = false
        }
      },
      deleteDocument (doc) {
        let idx = findIndex(this.documents, function(d) { return d.id == doc.id; });
        if (idx != -1) {
          this.documents.splice(idx, 1)
        }
        this.$store.commit('SET_STATE', {path: ['claim', 'attachments'], value: this.documents})
        this.$store.commit('SET_STATE', {path: ['attachmentChanged'], value: !isEqual(this.documents, this.$store.state.initialAttachments)})
      }
    }
  }
</script>

<style lang='scss' scoped>

  button.simple {
    margin-top: 19px;
  }

  .disable {
    margin: 0!important;
    padding: 0!important;
  }

  .documents {
    width: 100%;
    .document {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link {
        color: rgb(42, 70, 195);
        font-size: 16px;
        max-width: 65vw;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .action {
        font-size: 14px;
        padding: 2px 5px;
        border-radius: 5px;
        background: rgb(245, 245, 245);
        margin-left: 16px;
      }
      &:first-child {
        margin-top: 24px;
      }
      &:last-child {
        margin-bottom: 24px;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

</style>
