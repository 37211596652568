<template>
  <div class="drop-menu">
    <div class="close-menu" @click="changeMenuState()">
      <i class="mfcicon-menu"></i>
    </div>

    <div class="open-menu" :class="{show: showMenu}">
      <div class="menu-sheet">

        <div class="header">
          <a class="header__logo" :href="$routes.root_path()">
            <img :src="mfcLogo" alt="">
          </a>
          <div class="header__back-icon" @click="changeMenuState()">
            <i class="mfcicon-arrow-previous"></i>
          </div>
        </div>

        <div class="user-info" v-if="currentUser">
          <a class="user-info__avatar" :href="$routes.citizen_profile_path()">
            <span>{{getFullnameAbbreviation}}</span>
          </a>
          <a class="user-info__fullname" :href="$routes.citizen_profile_path()">
            {{userFullName}}
          </a>
          <div class="user-info__signout">
            <a :href="testEsia ? $routes.user_esia_oauth_test_omniauth_authorize_path({logout: true}) : $routes.user_esia_oauth_omniauth_authorize_path({logout: true})">Выйти</a>
          </div>
        </div>

        <div class="sections">
          <a v-if="currentUser" :href="$routes.active_index_citizen_claims_path()">
            <span>Активные заявки</span>
          </a>
          <a v-if="currentUser" :href="$routes.complete_index_citizen_claims_path()">
            <span>Завершённые заявки</span>
          </a>
          <a :href="$routes.about_path()">
            <span>О проекте</span>
          </a>
          <a :href="$routes.equipment_path()">
            <span>Требования к оборудованию</span>
          </a>
          <a :href="$routes.faq_path()">
            <span>FAQ</span>
          </a>
          <div v-html="footerLinks"></div>
          <a v-if="currentUser" :href="$routes.negotiation_path()">
            <span>Тестовая переговорная</span>
          </a>
        </div>

        <div class="active-container" >
          <a class="button primary" v-show="!currentUser" :href="testEsia ? $routes.user_esia_oauth_test_omniauth_authorize_path() : $routes.user_esia_oauth_omniauth_authorize_path()">
            Войти
          </a>
        </div>

        <div class="footer">
          <div class="info-text" v-html="footerInfo"></div>
          <div class="subinfo-text" v-html="footerCopyright"></div>
        </div>
      </div>
    </div>

    <div class="background-shadow" :class="{show: showMenu}" @click="changeMenuState()">
    </div>

  </div>
</template>

<script>
  import mfcLogo from 'assets/images/logo.png';

  export default {
    name: 'DropMenu',
    data() {
      return {
        mfcLogo: mfcLogo,
        showMenu: false
      }
    },
    props: ["currentUser", "userFullName", "footerLinks", "footerInfo", "footerCopyright", "testEsia"],

    mounted(){
      document.querySelector('html').classList.remove("not-scrolling");
    },
    computed: {
      getFullnameAbbreviation(){
        return this.currentUser.surname[0] + this.currentUser.name[0]
      }
    },
    methods: {
      changeMenuState(){
        this.showMenu = this.showMenu ? false : true

        if (this.showMenu) {
          document.querySelector('html').classList.add("not-scrolling");
          document.querySelector('body').classList.add("not-scrolling");
        }
        else {
          document.querySelector('html').classList.remove("not-scrolling");
          document.querySelector('body').classList.remove("not-scrolling");
        }
        return this.showMenu
      },
    }
  }
</script>

<style lang='scss'>
  .drop-menu {
    .close-menu {
      height: 64px;
      width: 32px;
      display: flex;
      align-items: center;
      .mfcicon-menu:before {
        font-size: 30px;
      }
    }

    .open-menu {
      display: flex;
      position: absolute;
      top: 0;
      left: -100vw;
      bottom: 0;
      min-width: 288px;
      min-height: 100vh;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
      z-index: 9999;
      &.show {
        transform-origin: center;
        transform: translateX(100vw);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
      }
      .menu-sheet {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        width: 83vw;
        height: 100vh;
        background-color: #fff;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px;
          min-height: 64px;
          border-bottom: 1px solid rgb(234, 228, 220);
          .header__logo {
            width: 175px;
          }
          .header__back-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #f3f0ec;
          }
        }

        .sections {
          a {
            display: block;
            text-decoration: none;
            color: #000;
            position: relative;
            padding: 16px 17px;
            border-bottom: 1px solid rgb(234, 228, 220);
            span {
              color: rgb(38, 38, 38);
              font-size: 18px;
            }
            &:active::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 4px;
              height: 100%;
              background: rgb(237, 83, 56);
            }
          }
        }

        .user-info {
          min-height: 88px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 22px;
          background-color: #f3f0ec;
          border-bottom: 1px solid rgb(234, 228, 220);

          .user-info__avatar {
            min-width: 44px;
            max-width: 44px;
            min-height: 44px;
            max-height: 44px;
            background-color: #ce9b6b;
            border-radius: 50%;
            text-align: center;
            padding: 15px 0;
            span {
              color: rgb(81, 21, 2);
              font-size: 15px;
              font-family: Circe;
              font-weight: 600;
              text-transform: uppercase;
            }
          }
          .user-info__fullname {
            margin-right: auto;
            margin-left: 20px;
            max-width: 196px;
            color: rgb(38, 38, 38);
            font-size: 18px;
          }
          .user-info__signout {
            a {
              color: rgb(0, 0, 0);
              font-size: 18px;
              margin-left: 10px;
              opacity: 0.5;
            }
          }
        }

        .active-container {
          margin-bottom: auto;
          padding: 17px 16px;
          .button.primary {
            width: 100%;
            text-align: center;
            max-width: 288px;
          }
        }

        .footer {
          width: 100%;
          padding: 18px 16px 48px;
          background-color: #f3f0ec;
          .info-text {
            max-width: 275px;
            p {
              color: rgb(38, 38, 38);
              font-size: 14px;
              line-height: 16px;
            }
          }
          .subinfo-text {
            max-width: 289px;
            p {
              color: rgb(141, 141, 141);
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .background-shadow {
      display: none;
      &.show {
        position: absolute;
        min-width: 100vw;
        min-height: 100vh;
        margin-top: -64px;
        margin-left: -16px;
        background-color: #000;
        display: block;
        z-index: 999;
        opacity: 0.6;
        animation: shadow 0.5s linear;
      }
    }
  }

  @keyframes shadow {
    from {opacity: 0;}
    1% {opacity: 0.6;}
    to {opacity: 0.6;}
  }

</style>
