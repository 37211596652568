import Vue from 'vue/dist/vue.esm'
import {upperFirst, camelCase, kebabCase} from 'lodash'
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

const requireComponent = require.context('../mobile_js/components', true, /\.(vue|js)$/)
const claimFormComponent = require.context('../components/claim_form', true, /\.(vue|js)$/)

const requireDirective = require.context('../mobile_js/directives', true, /\.(js)$/)

const requireFilter = require.context('../mobile_js/filters', true, /\.(js)$/)

const requirePlugin = require.context('../plugins', true, /\.(js)$/)
const requireMobilePlugin = require.context('../mobile_js/plugins', true, /\.(js)$/)

const registerComponent = (requireContext, fileName) => {
  const componentConfig = requireContext(fileName)
  const componentName = camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  Vue.component(componentName, componentConfig.default || componentConfig)
}

requireComponent.keys().forEach(registerComponent.bind(null, requireComponent))
claimFormComponent.keys().forEach(registerComponent.bind(null, claimFormComponent))

requireFilter.keys().forEach(fileName => {
  const filterConfig = requireFilter(fileName)

  const filterName = camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.filter(
    filterName,
    filterConfig.default || filterConfig
  )
})

requireDirective.keys().forEach(fileName => {
  const directiveConfig = requireDirective(fileName)

  const directiveName = kebabCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

  Vue.directive(
    directiveName,
    directiveConfig.default || directiveConfig
  )
})

requirePlugin.keys().forEach(fileName => {
  const plugin = requirePlugin(fileName)

  Vue.use(plugin.default || plugin)
})


requireMobilePlugin.keys().forEach(fileName => {
  const plugin = requireMobilePlugin(fileName)

  Vue.use(plugin.default || plugin)
})
