<template>
  <div class="trueconf-timer" row="">
    <div class="timer-item">
      <div class="timer-item__digital-container">
        <p class="timer-item__digital" id="days"></p>
      </div>
    </div>
        <span>:</span>
    <div class="timer-item">
      <div class="timer-item__digital-container">
        <p class="timer-item__digital" id="hours"></p>
      </div>
    </div>
        <span>:</span>
    <div class="timer-item">
      <div class="timer-item__digital-container">
        <p class="timer-item__digital" id="minutes"></p>
      </div>
    </div>
        <span>:</span>
    <div class="timer-item">
      <div class="timer-item__digital-container">
        <p class="timer-item__digital" id="seconds"></p>
      </div>
    </div>
  </div>
</template>

<script>
import ActionCable from 'actioncable'

export default {
  name: 'Timer',
  props: {
    start_time: {
      type: String,
      required: true
    },
    time_now: {
      type: String,
      required: true
    }
  },
  methods: {
    find_time(left_time) {
      let result = {
        'days':0,
        'hours': 0,
        'minutes': 0,
        'seconds': 0
      };

      if(left_time > 0) {
        // Convert time (milliseconds to ...)
        let second = 1000;
        let minute = second * 60;
        let hour = minute * 60;
        let day = hour * 24;

        // Find values
        let left_days = left_time / day;
        result['days'] = parseInt(left_days);

        let left_hours = (left_days - result['days']) * day / hour;
        result['hours'] = parseInt(left_hours);

        let left_minutes = (left_hours - result['hours']) * hour / minute;
        result['minutes'] = parseInt(left_minutes);

        let left_seconds = (left_minutes - result['minutes']) * minute / second;
        result['seconds'] = parseInt(left_seconds);
      }

      return result;
    },
    left_time(event_date) {
      let now_date = new Date();
      if (this.offset) now_date.setTime(now_date.getTime() + this.offset)

      // how much time is left
      let left = new Date(event_date.getTime() - now_date.getTime());
      left = left.getTime();

      return this.find_time(left);
    },
    pad(num) {
      num = num.toString();
      if(num.length == 1) {
        return "0" + num;
      }
      return num;
    },
    calcOffset() {
      const serverDate = this.time_now
      const serverTimeMillisGMT = Date.parse(this.$moment.utc(serverDate, "YYYY-MM-DD HH:mm:ss").toDate().toUTCString());
      const localMillisUTC = Date.parse(new Date().toUTCString());
      this.offset = serverTimeMillisGMT -  localMillisUTC;
    }
  },
  mounted: function () {
    this.calcOffset()
    let timer = this;
    let interval = setInterval(() => {
      let left = this.left_time(this.$moment.utc(this.start_time, "YYYY-MM-DD HH:mm:ss").toDate());
      if(left['days'] <= 0 && left['hours'] <= 0 && left['minutes'] <= 0 && left['seconds'] <= 0) {
        clearInterval(interval);
        window.location.reload(false);
      }

      days.innerHTML = timer.pad(left['days']);
      hours.innerHTML = timer.pad(left['hours']);
      minutes.innerHTML = timer.pad(left['minutes']);
      seconds.innerHTML = timer.pad(left['seconds']);
    }, 1000);
  }
}
</script>

<style lang="scss">

  .trueconf-timer {
    background: #323232;
    border: 2px solid black;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    font-family: Circe;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    height: 515px;
    margin-bottom: 49px !important;

    span {
      margin-bottom: 10px;
    }

    .timer-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;


      .timer-item__digital-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        // border-radius: 50%;
        // border: 1px solid #fff;
        width: 60px;
        height: 60px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .timer-item__digital {
          text-align: center;
          line-height: normal;
          font-size: 45px;
          margin: auto;
          padding: 0;
        }
      }
    }
  }
</style>
