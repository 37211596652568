<template>
  <div class="accordion__item" :class="{'border-bottom': borderBottom}">
    <div 
      class="accordion__trigger"
      :class="{'accordion__trigger_active': visible}"
      @click="open">


      <div class="accordion__trigger--content">

        <div class="title">
          <slot name="accordion-title"></slot>
        </div>
        <i class="mfcicon-arrow-down"></i>

      </div>
    </div>

    <transition 
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end">

      <div class="accordion__content"
        v-show="visible">

        <div class="accordion__content--content">
          <slot name="accordion-content"></slot>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    borderBottom: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      visible: false,
      index: null
    };
  },
  methods: {
    open() {
      this.visible = !this.visible
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  }
};
</script>

<style lang="scss" scoped>
  .accordion__item {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    position: relative;
  }

  .accordion__trigger--content {
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
    i {
      font-size: 13px;
      color: #CECECE;
      transition: transform 0.3s ease,;
    }

  }
  .accordion__trigger_active > .accordion__trigger--content {
    border-color: transparent;
    i {
      transform: rotate(180deg);
    }
  }
  .accordion__content {
    &--content {
      padding-bottom: 20px;
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity, margin-bottom;
    transition: height 0.3s ease, opacity 0.3s ease, margin-bottom 0.3s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }

  .border-bottom {
    border-bottom: 1px solid #ebebeb;
  }
</style>
