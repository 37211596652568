
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  data () {
    return {
      showStates: false,
      modalConfirm: false,
      stateName: null
    }
  },
  methods: {
    hide () {
      this.showStates = false
    },
    showModal (link, state, currentAdmin) {
      this.stateName = state;
      if (state == "refuze") {
        this.$store.commit('SET_STATE', {path: ['refuzePartialShow'], value: true})
      }
      else if (currentAdmin && ["consider","consult","clarify"].includes(state)) {
        this.$store.commit('SET_STATE', {path: ['performerPartialShow'], value: state})
      } 
      else {
        this.goToState(link)
      }
    },
    goToState (link) {
      Turbolinks.visit(link)
    }
  }
}
